import React from "react";
import moment from "moment";
import "./styles/login.scss";

import { API } from "../Api";

function Login({ setCurrentTab = () => {} }) {
  const [username, setUserName] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [errorMsg, setErrorMsg] = React.useState("");
  const [showError, setShowError] = React.useState(false);

  const handleLogin = async () => {
    if (!username || !password) {
      setShowError(true);
      return setErrorMsg("Please fill all fields!");
    }
    try {
      const res = await API.userlogin(username, password);
      if (res.status === 200 && res.data) {
        localStorage.setItem("access_token", res.data?.access_token);
        localStorage.setItem("refresh_token", res.data?.refresh_token);
        localStorage.setItem("timestamp", moment());
        setCurrentTab("My Images");
      } else {
        setShowError(true);
        setErrorMsg("Wrong username or password!");
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="login-wrapper">
      <div className="form_container">
        <div className="form_control">
          <input
            type="value"
            required
            onChange={(e) => {
              setShowError(false);
              setUserName(e.target.value);
            }}
            value={username}
          />
          <label>
            {"Username".split("").map((letter, index) => (
              <span key={index} style={{ transitionDelay: `${index * 50}ms` }}>
                {letter}
              </span>
            ))}
          </label>
          <div className="red align-left">
            {!username && showError && "Please enter username"}
          </div>
        </div>
        <div className="form_control">
          <input
            type="password"
            required
            onChange={(e) => {
              setShowError(false);
              setPassword(e.target.value);
            }}
            value={password}
            autoComplete="new-password"
          />
          <label>
            {"Password".split("").map((letter, index) => (
              <span key={index} style={{ transitionDelay: `${index * 50}ms` }}>
                {letter}
              </span>
            ))}
          </label>
          <div className="red align-left">
            {!password && showError && "Please enter password"}
          </div>
        </div>
        <button className="form_button" onClick={handleLogin}>
          Sign-In
        </button>
      </div>
      <div className="red">{showError && errorMsg}</div>
    </div>
  );
}

export default Login;
