import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import moment from "moment";

import UploadImage from "./components/UploadImage";
import Navbar from "./components/Navbar";
import Login from "./components/Login";
import MyImages from "./components/MyImages";
// import AboutUs from "./components/AboutUs";
// import ContactUs from "./components/ContactUs";

function App() {
  const [currentTab, setCurrentTab] = React.useState(
    localStorage.getItem("timestamp") === null ? "Login" : "My Images"
  );

  React.useEffect(() => {
    const timestamp = localStorage.getItem("timestamp");
    if (timestamp === null) return setCurrentTab("Login");
    const hours = moment().diff(timestamp, "hours");
    if (hours >= 24) {
      setCurrentTab("Login");
      localStorage.removeItem("timestamp");
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
    } else setCurrentTab("My Images");
  }, []);

  let component = null;
  if (currentTab === "Login")
    component = <Login setCurrentTab={setCurrentTab} />;
  else if (currentTab === "Upload")
    component = <UploadImage setCurrentTab={setCurrentTab} />;
  else component = <MyImages />;

  return (
    <div className="App">
      <Navbar currentTab={currentTab} setCurrentTab={setCurrentTab} />
      {component}
    </div>
  );
}

export default App;
