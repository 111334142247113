import React from "react";
import { Modal, Image } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faClose,
  faArrowUp,
} from "@fortawesome/free-solid-svg-icons";

import "./styles/myimages.scss";
import { API } from "../Api";
import { Loader } from "./Common";

const limit = 10;
function MyImages() {
  const observer = React.useRef();
  const [myImages, setMyImages] = React.useState([]);
  const [totalImages, setTotalImages] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const [showModal, setShowModal] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [currentImage, setCurrentImage] = React.useState(null);
  const [imageId, setImageId] = React.useState("");

  const lastItemRef = React.useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && totalImages > myImages.length)
          getMyImages(Math.ceil(myImages.length / limit) + 1);
      });
      if (node) observer.current.observe(node);
    },
    [loading, totalImages, myImages]
  );

  const getMyImages = async (page = 1) => {
    setLoading(true);
    const params = {
      page,
      limit,
      sortBy: -1,
    };
    try {
      const res = await API.getAllImages(params);
      if (res.status === 200 && res.data?.data?.length) {
        setMyImages([...myImages, ...res.data?.data]);
        setTotalImages(res.data?.totalCount.totalRecords);
        setLoading(false);
      } else {
        setMyImages([]);
        setTotalImages(0);
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleClick = (image = null) => {
    setShowModal(true);
    setCurrentImage(image);
    setImageId(image._id);
  };

  const handleDelete = async () => {
    try {
      const res = API.deleteImage(imageId);
      if (res.status === 200) {
        setShowDeleteModal(false);
        setShowModal(false);
        getMyImages();
      }
    } catch (e) {
      console.log(e);
    }
  };

  React.useEffect(() => {
    getMyImages();
  }, []);

  return (
    <div className="content-wrapper myimages-wrapper">
      <div
        className="scroll-top"
        onClick={() => window.scrollTo({ top: 0, left: 0, behavior: "smooth" })}
      >
        <FontAwesomeIcon icon={faArrowUp} color="white" size="2x" />
      </div>
      <h1>My Images</h1>
      {totalImages > 0 ? (
        <div className="main-content">
          <div className="images-container">
            {myImages.map((image = {}, index) => (
              <div
                onClick={() => handleClick(image)}
                key={image?._id + index}
                className="image-card"
                ref={index + 1 < myImages.length ? null : lastItemRef}
              >
                <div className="overlay">
                  <div
                    className="delete-icon"
                    onClick={() => {
                      setImageId(image._id);
                      setShowDeleteModal(true);
                    }}
                  >
                    <FontAwesomeIcon icon={faClose} color="red" size="2x" />
                  </div>
                  <div className="details">
                    <div>
                      Uploaded:{" "}
                      {new Date(image?.createdAt).toLocaleDateString("en-US")}
                    </div>
                    <a
                      href={image?.imagePath}
                      rel="noreferrer"
                      target="_blank"
                      className="cursor-pointer white"
                    >
                      <FontAwesomeIcon icon={faDownload} />
                    </a>
                  </div>
                </div>
                <img src={image?.imagePath} alt="card-pic" />
              </div>
            ))}
          </div>
          <Modal
            className="view-image-modal"
            centered
            show={showModal}
            onHide={() => setShowModal(false)}
          >
            <Modal.Body>
              <Image src={currentImage?.imagePath} alt="zoom-in" />
              <div className="d-flex white justify-content-between px-2 pt-2">
                <div>
                  Uploaded:{" "}
                  {new Date(currentImage?.createdAt).toLocaleDateString(
                    "en-US"
                  )}
                </div>
                <a
                  href={currentImage?.imagePath}
                  rel="noreferrer"
                  target="_blank"
                  className="cursor-pointer white"
                  // onClick={() => downloadImage(image?.imagePath)}
                >
                  <FontAwesomeIcon icon={faDownload} />
                </a>
              </div>
            </Modal.Body>
          </Modal>
          <Modal
            className="delete-image-modal"
            centered
            show={showDeleteModal}
            onHide={() => setShowDeleteModal(false)}
          >
            <Modal.Body>
              <div>
                <h4>
                  Are you sure you want to <span className="red">delete</span>{" "}
                  this image from Gallery?
                </h4>
              </div>
              <div className="d-flex gap-4 mt-4">
                <button className="delete-button" onClick={handleDelete}>
                  Delete
                </button>
                <button
                  className="cancel-button"
                  onClick={() => {
                    setShowModal(false);
                    setShowDeleteModal(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      ) : loading ? (
        <Loader />
      ) : (
        <div className="py-5">No Images present in gallery</div>
      )}
    </div>
  );
}

export default MyImages;
