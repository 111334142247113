import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import "./styles/navbar.scss";

const menu = ["Upload", "My Images", "Logout"];
function Navbar({ currentTab = "", setCurrentTab = () => {} }) {
  const [show, setShow] = React.useState(false);

  const handleLogout = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("timestamp");
    setCurrentTab("Login");
  };

  React.useEffect(() => {}, [currentTab]);

  return (
    <div className="navbar-wrapper">
      <div className="title">Image Upload</div>
      {currentTab !== "Login" && (
        <div className="menu">
          <div className="list">
            {menu.map((menu, index) => {
              return (
                <div
                  className={currentTab === menu ? "active" : ""}
                  onClick={() => {
                    if (index === 2) handleLogout();
                    else if (index === 0) setCurrentTab("Upload");
                    else if (index === 1) setCurrentTab("My Images");
                  }}
                >
                  {menu}
                </div>
              );
            })}
          </div>
          <div className="drawer-icon" onMouseLeave={() => setShow(false)}>
            <FontAwesomeIcon icon={faBars} onMouseDown={() => setShow(!show)} />
            <div
              className="menu-dropdown"
              style={show ? { display: "block" } : { display: "none" }}
            >
              {menu.map((menu, index) => {
                return (
                  <>
                    <div
                      className={currentTab === menu ? "active" : ""}
                      onClick={() => {
                        setShow(false);
                        if (index === 2) handleLogout();
                        else if (index === 0) setCurrentTab("Upload");
                        else if (index === 1) setCurrentTab("My Images");
                      }}
                    >
                      {menu}
                    </div>
                    {index === menu.length - 4 ? null : <hr />}
                  </>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Navbar;
