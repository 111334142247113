import React from "react";
import axios from "axios";
import { Button, Modal } from "react-bootstrap";
import Compressor from "compressorjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faUpload,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";

import "./styles/uploadimage.scss";

function UploadImage({ setCurrentTab = () => {} }) {
  const inputRef = React.useRef(null);
  const [uploaded, setUploaded] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [selectedFiles, setSelectedFiles] = React.useState([]);
  const [progressInfo, setProgressInfo] = React.useState([]);
  const [message, setMessage] = React.useState([]);

  const uploadFile = async (index, formData = {}) => {
    try {
      const res = await axios.post(
        process.env.REACT_APP_API_ENDPOINT + "image/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
          onUploadProgress: (e) => {
            let pinfo = progressInfo;
            let msg = message;
            pinfo[index].percent = Math.round(100 * e.loaded) / e.total;
            setProgressInfo([...pinfo]);
            msg[index].msg = "Uploading . . .";
            setMessage([...msg]);
          },
        }
      );
      if (res.status === 200) {
        let msg = message;
        msg[index].msg = "Upload Complete!";
        setMessage([...msg]);

        progressInfo.forEach((image) => {
          if (image?.percent === 100) {
            setUploaded(true);
            setShowModal(true);
          }
        });
      } else {
        let msg = message;
        msg[index].msg = "Cannot Upload!";
        setMessage([...msg]);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const formCompressedImage = async (index, file = {}) => {
    const formData = new FormData();
    try {
      new Compressor(file, {
        quality: 0.8,
        success: (compressedImage) => {
          let fileName = compressedImage.name;
          fileName = fileName.replaceAll(" ","");
          fileName = fileName.replaceAll("(","");
          fileName = fileName.replaceAll(")","");
          fileName = fileName.replaceAll("{","");
          fileName = fileName.replaceAll("}","");
          fileName = fileName.replaceAll("[","");
          fileName = fileName.replaceAll("]","");
          fileName = fileName.replaceAll("=","");
          fileName = fileName.replaceAll(";","");
          fileName = fileName.replaceAll("/","");
          formData.append("file", compressedImage, compressedImage.lastModified+fileName);
          uploadFile(index, formData);
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handleFile = (e) => {
    setSelectedFiles([...e.target.files]);
    const files = Array.from(e.target.files);
    let pinfo = [];
    let msg = [];
    files?.forEach((file) => {
      pinfo.push({ percent: 0, fileName: file?.name });
      msg.push({ msg: "" });
    });
    setProgressInfo([...pinfo]);
    setMessage([...msg]);
  };

  const handleUpload = () => {
    selectedFiles.forEach((file = {}, index) => {
      formCompressedImage(index, file);
    });
  };

  return (
    <div className="content-wrapper uploadimage-wrapper">
      <h1>Upload your image</h1>
      <div className="main-content">
        <div className="left-section">
          <input
            multiple
            className="d-none"
            ref={inputRef}
            type="file"
            accept="image/*"
            onChange={handleFile}
          />
          <button onClick={() => inputRef.current.click()}>
            <FontAwesomeIcon icon={faSearch} />
            &nbsp;
            <span>Browse files</span>
          </button>
          <Button
            disabled={!selectedFiles.length || uploaded}
            onClick={handleUpload}
          >
            <FontAwesomeIcon icon={faUpload} />
            &nbsp;
            <span>Upload</span>
          </Button>
        </div>
        <div className="right-section">
          {selectedFiles?.length > 0 ? (
            selectedFiles?.map((image, index) => (
              <>
                <div className="progress-image-wrapper">
                  <div className="progress-bar-wrapper">
                    <div className="align-left">#{index + 1}</div>
                    <div className="mt-auto mb-auto">
                      <div>{message[index]?.msg}</div>
                      <div className="progress">
                        <div
                          role="progressbar"
                          aria-valuenow={progressInfo[index]?.percent}
                          aria-valuemin="0"
                          aria-valuemax="100"
                          className="progress-bar progress-bar-striped"
                          style={{ width: `${progressInfo[index]?.percent}%` }}
                        />
                      </div>
                      <p className="mb-0">{progressInfo[index]?.percent}%</p>
                    </div>
                    <div className="align-left">
                      Filename: {progressInfo[index]?.fileName}
                    </div>
                  </div>
                  <div className="image-wrapper">
                    <img src={URL.createObjectURL(image)} alt="img-display" />
                  </div>
                </div>
                {index === selectedFiles.length - 1 ? null : <hr />}
              </>
            ))
          ) : (
            <div>OOPS! Looks like you have not selected any image</div>
          )}
        </div>
      </div>
      <Modal
        className="upload-success-modal"
        centered
        show={showModal}
        onHide={() => setShowModal(false)}
      >
        <Modal.Body className="d-flex flex-column px-5 py-4">
          <div
            className="align-center blue"
            style={{ fontSize: 20, fontWeight: 600 }}
          >
            All images uploaded successfully!
          </div>
          <br />
          <button
            className="border-blue blue bg-white rounded"
            onClick={() => setCurrentTab("My Images")}
          >
            <span>View in gallery</span>
            &nbsp;
            <FontAwesomeIcon icon={faArrowRight} />
          </button>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default UploadImage;
