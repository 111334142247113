import axios from "axios";

const API_END_POINT = process.env.REACT_APP_API_ENDPOINT;
const instance = axios.create({
  baseURL: API_END_POINT,
  headers: { "Content-Type": "application/json" },
});
// const instanceFormData = axios.create({
//   baseURL: API_END_POINT,
//   headers: { "Content-Type": "application/form-data" },
// });

const apiCall = async ({
  method = "GET",
  url = "",
  params = {},
  data = {},
  timeout = 7000,
}) => {
  try {
    const response = await instance({ method, url, params, data, timeout });
    return response.data;
  } catch (error) {
    return error?.response?.data || {};
  }
};

// const formDataApiCall = async ({
//   method = "POST",
//   url = "",
//   params = {},
//   formData: data = {},
//   timeout = 7000,
// }) => {
//   try {
//     const response = await instance({ method, url, params, data, timeout });
//     return response.data;
//   } catch (error) {
//     return error?.response?.data || {};
//   }
// };

instance.interceptors.request.use((config) => {
  const access_token = localStorage.getItem("access_token");
  if (access_token) config.headers.Authorization = "Bearer " + access_token;
  return config;
});

export const API = {
  userlogin: (userName, password) =>
    apiCall({
      method: "POST",
      url: "user/login",
      data: { userName, password },
    }),

  getAllImages: (params = {}) =>
    apiCall({
      url: "image/getAll",
      params,
    }),

  deleteImage: (imageId = "") => {
    apiCall({
      method: "DELETE",
      url: "image/" + imageId,
    });
  },

  // uploadImage: (file) => {
  //   const formData = new FormData();
  //   formData.append("file", file);
  //   return formDataApiCall({
  //     url: "image/upload",
  //     formData: formData,
  //     timeout: 50000,
  //   });
  // },
};
